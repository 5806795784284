export default {
  namespaced: true,
  state: {
    menu: [
      {
        name: "Inicio",
        url: "/contabilidad",
        icon: "mdi-home",
        permissions: ["HOME"],
      },
      {
        name: "Plan de cuentas",
        url: "/lista_cuentas",
        icon: "mdi-view-list-outline",
        permissions: ["CONTA_PLAN_CUENTAS"],
      },
      {
        name: "Cajas",
        url: "/cajas",
        icon: "mdi-cash-multiple",
        permissions: ["CONTA_CAJAS"],
      },
      {
        name: "Bancos",
        url: "/bancos",
        icon: "mdi-bank",
        config: true,
        permissions: ["BANCOS", "LIBRO_BANCOS"],
        dropDown: [
          {
            name: "Libro banco",
            url: "/libro_banco",
            icon: "mdi-bank-check",
            permissions: ["LIBRO_BANCOS"],
          },
        ],
      },
      {
        name: "Reportes",
        url: "/reportes",
        icon: "mdi-file-document-multiple",
        config: true,
        permissions: [
          "REP_LIB_MAYOR_SALDO",
          "REP_UNIFICADO_GASTO",
          "CONTABILIDAD_ACTIVAR_ASIENTO",
        ],
        dropDown: [
          {
            name: "Libro mayor con saldos",
            url: "/libro-mayor-saldos",
            icon: "mdi-format-list-checks",
            permissions: ["REP_LIB_MAYOR_SALDO"],
          },
          {
            name: "Rep unficado de gastos",
            url: "/reporte-unificado-gastos",
            icon: "mdi-format-list-checks",
            permissions: ["REP_UNIFICADO_GASTO"],
          },
          {
            name: "Estado de resultados",
            url: "/estado_resultados",
            icon: "mdi-format-list-checks",
            permissions: ["CONTABILIDAD_ACTIVAR_ASIENTO"],
          },
          {
            name: "Balance General",
            url: "/balance_general",
            icon: "mdi-format-list-checks",
            permissions: ["CONTABILIDAD_ACTIVAR_ASIENTO"],
          },
          {
            name: "Balance Mensual",
            url: "/balanceMensual",
            icon: "mdi-format-list-checks",
            permissions: ["CONTABILIDAD_ACTIVAR_ASIENTO"],
          },
          {
            name: "Servicios a terceros",
            url: "/servicios-terceros",
            icon: "mdi-format-list-checks",
            permissions: ["CONTABILIDAD_ACTIVAR_ASIENTO"],
          },

          {
            name: "Ventas por producto",
            url: "/reporte_ventas_producto",
            icon: "mdi-format-list-checks",
            permissions: ["CONTABILIDAD_ACTIVAR_ASIENTO"],
          },
        ],
      },
      {
        config: true,
        name: "Facturas",
        icon: "mdi-receipt",
        permissions: ["CONTA_CCOBRAR", "CONTA_CPAGAR"],
        dropDown: [
          {
            name: "Venta",
            url: "/lista_facturas_venta",
            icon: "mdi-format-list-checks",
            permissions: ["CONTA_CCOBRAR"],
          },
          {
            name: "Compras",
            url: "/lista_facturas_compra",
            icon: "mdi-format-list-checks",
            permissions: ["CONTA_CPAGAR"],
          },

          {
            name: "Rol de pago",
            url: "/lista_facturas_rol",
            icon: "mdi-format-list-checks",
            permissions: ["CONTA_ROLES_PAGO"],
          },
        ],
      },

      {
        name: "Retenciones",
        url: "/retenciones",
        icon: "mdi-format-list-checks",
        permissions: ["CONTA_CPAGAR"],
      },
      {
        name: "Comprobantes electrónicos",
        url: "/comprobantes-electronicos-recibidos",
        icon: "mdi-format-list-checks",
        permissions: ["LISTA_COMPROB_ELECTR"],
      },

      {
        name: "Conciliacion Bancos",
        url: "/conciliacion_bancos",
        icon: "mdi-format-list-checks",
        permissions: ["CONTA_CONCILIACION"],
      },
      {
        name: "Periodos contables",
        url: "/periodos_contables",
        icon: "mdi-format-list-checks",
        permissions: ["CONTA_CPAGAR"],
      },
      {
        name: "Retenciones de tarjetas",
        url: "/retencion_tarjetas",
        icon: "mdi-format-list-checks",
        permissions: ["CONTA_RET_TARJETAS"],
      },
      {
        config: true,
        name: "Pagos",
        icon: "mdi-cash-multiple",
        permissions: ["CONTA_CPAGAR"],
        dropDown: [
          {
            name: "Pagos emitidos",
            url: "/pagos-emitidos",
            icon: "mdi-format-list-checks",
            permissions: ["CONTA_CPAGAR"],
          },
          {
            name: "Reembolsos",
            url: "/reembolsos-clientes",
            icon: "mdi-format-list-checks",
            permissions: ["CONTA_CPAGAR"],
          },
        ],
      },
      {
        config: true,
        name: "Cobranzas",
        icon: "mdi-cash-multiple",
        permissions: ["CONTA_CCOBRAR"],
        dropDown: [
          {
            name: "Pagos recibidos",
            url: "/pagos_recibidos",
            icon: "mdi-format-list-checks",
            permissions: ["CONTA_CCOBRAR"],
          },
        ],
      },

      {
        name: "Organizaciones",
        url: "/organizaciones",
        icon: "mdi-format-list-checks",
        permissions: ["CONTA_CCOBRAR"],
      },

      {
        name: "Ordenes de Pago",
        url: "/payment-group",
        icon: "mdi-format-list-checks",
        permissions: ["CONTA_CPAGAR"],
      },
    ],
  },
};
